import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import SEO from '../../components/seo';
import PresentationListItem from '../../features/presentation/presentationListItem';

const isProd = process.env.NODE_ENV === 'production';

const useWorkshops = () => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/content/workshops/.*/index.md/" } }) {
        nodes {
          frontmatter {
            title
            date
            description
            materialsUrl
            #events
            notPublished
            videoUrl
          }
          fields {
            slug
          }
        }
      }
    }
  `);
  return data.allMarkdownRemark.nodes;
};
const PresentationsPage = () => {
  const presentations = useWorkshops();

  return (
    <>
      <SEO title="Szkolenia" />
      <>
        <h1>Szkolenia</h1>
        <p>Prezentacje i materiały do przeprowadzanych szkoleń.</p>
        {presentations.map((presentation) => {
          if (presentation.frontmatter.notPublished && isProd) {
            return null;
          }
          return (
            <PresentationListItem
              key={`presentation-${presentation.fields.slug}`}
              slug={presentation.fields.slug}
              title={presentation.frontmatter.title}
              description={presentation.frontmatter.description}
              materialsUrl={presentation.frontmatter.materialsUrl}
              events={presentation.frontmatter.events}
              videoUrl={presentation.frontmatter.videoUrl}
            />
          );
        })}
      </>
    </>
  );
};

export default PresentationsPage;
